// Example:
// [fieldName]: {
//     maxLength: 100, //Максимальная длина строки ✅
//     minLength: 5, // Минимальная длина строки ✅
//     isEmail: true, // Проверка на email ✅
//     isRequired: true, // Поле обязательно для заполнения ✅
//     onlyCyrilic: true, // Поле может содержать только кириллические символы ✅
//     onlyCyrilicAndNumbers: true, // Поле может содержать только кириллические символы и цифры ✅
//     withoutSpaces: true, // Поле не может содержать пробелы ✅
//     onlyLat: true, // Поле может содержать только латинские символы ✅
//     onlyLatAndNumbers: true, // Поле может содержать только латинские символы и цифры ✅
//     isUniq: true, // Значение поля уникально для всей формы ✅
//     customUniq: customUniq || [], // Проверка на уникальность данных с учетом переданного массива
//     isIp: true, // Проверка поля на соответвие ip адресу ✅
//     isUrl: true, // Проверка поля на соответвие url адресу ✅
//     isInn: true, // Проверка поля на соответвие ИНН ✅
//     isIpOrUrl: true, // Проверка поля на соответвие ip или url адресу ✅
//     maxValue: 65364, // Максимально значение (только для числового поля) ✅
//     minValue: 65364, // Минимальное значение (только для числового поля) ✅
//     requireUppercaseSymbol: true, // Поле должно содержать один заглавный символ ✅
//     requireNumberSymbol: true, // Поле должно содержать хотя бы одину цифру ✅
//     isIpMask: true, // Поле является ip маской ✅
//     oneOfList: oneOfList || [], // Значение поля должно соответствовать одину элементу в массиве ✅
//     joinStringIsIP: true, // Каждый элемент должен быть IP адресом ✅
//     isMobilePhone: true // Поле должно содержать номер телефона ✅
//     isPort: true // Значение поля должно быть портом,
//     isDate: true // Значение должно быть датой
//   }

export const UserValidationConfig = () => {
  return {
    email: {
      isEmail: true,
      isRequired: true,
      maxLength: 100,
    },
    firstName: {
      isRequired: true, // Временно пока в кейклоке не появятся данные
      maxLength: 100,
    },
    lastName: {
      isRequired: true, // Временно пока в кейклоке не появятся данные
      maxLength: 100,
    },
    middleName: {
      isRequired: false, // Временно пока в кейклоке не появятся данные
      maxLength: 100,
    },
    position: {
      isRequired: false, // Временно пока в кейклоке не появятся данные
      maxLength: 100,
    },
    phone: {
      isMobilePhone: true,
      isRequired: true,
    },
    groups: {
      isRequired: true,
    },
  };
};

export const CompanyValidationConfig = () => {
  return {
    email: {
      isEmail: true,
      isRequired: true,
      maxLength: 100,
    },
    phone: {
      isMobilePhone: true,
      isRequired: true,
    },

    structureName: {
      isRequired: true,
      oneOfList: ['ИП', 'ООО', 'АО', 'ПАО', 'ОАО', 'ТОО'],
      maxLength: 400,
    },
    inn: {
      isInn: true,
      onlyNumbers: true,
      minLength: 10,
      maxLength: 12,
      isRequired: true,
    },
    title: {
      maxLength: 1500,
      isRequired: true,
    },
    FullName: {
      maxLength: 1500,
      isRequired: true,
    },
    ShortName: {
      isRequired: true,
      maxLength: 512,
    },
    billingDescriptor: {
      isRequired: true,
      maxLength: 512,
      onlyLatAndNumbers: true,
    },
    Kpp: {
      isRequired: false,
      onlyNumbers: true,
      maxLength: 9,
    },
    Ogrn: {
      onlyNumbers: true,
      maxLength: 15,
    },
    Account: {
      isRequired: true,
      onlyNumbers: true,
      maxLength: 20,
    },
    KorAccount: {
      isRequired: true,
      onlyNumbers: true,
      maxLength: 20,
    },
    Bik: {
      isRequired: true,
      onlyNumbers: true,
      maxLength: 9,
    },
    BankName: {
      isRequired: true,
      maxLength: 300,
    },
    SiteUrl: {
      isRequired: true,
      isIpOrUrl: true,
      maxLength: 300,
    },
    CompanyEmail: {
      isEmail: true,
      isRequired: true,
      maxLength: 100,
    },
    Zip: {
      isRequired: true,
      maxLength: 6,
      onlyNumbers: true,
    },
    City: {
      isRequired: true,
      maxLength: 28,
      onlyCyrilicAndNumbers: true,
    },
    Street: {
      isRequired: true,
    },
    ActualAddress: {
      isRequired: true,
    },
    CeoLastName: {
      isRequired: true,
      maxLength: 100,
    },
    CeoFirstName: {
      isRequired: true,
      maxLength: 100,
    },
    CeoMiddleName: {
      isRequired: true,
      maxLength: 100,
    },
    CeoBirthDate: {
      isRequired: true,
      isDate: true,
    },
    CeoPhone: {
      isMobilePhone: true,
      isRequired: true,
    },
    CeoEmail: {
      isEmail: true,
      isRequired: true,
      maxLength: 100,
    },
    CeoCountry: {
      isRequired: true,
    },
    ContactLastName: {
      isRequired: true,
      maxLength: 100,
    },
    ContactFirstName: {
      isRequired: true,
      maxLength: 100,
    },
    ContactMiddleName: {
      isRequired: true,
      maxLength: 100,
    },
    ContactPhone: {
      isMobilePhone: true,
      isRequired: true,
    },
    ContactEmail: {
      isEmail: true,
      isRequired: true,
      maxLength: 100,
    },
  };
};

export const EmployeeValidationConfig = () => {
  return {
    email: {
      isEmail: true,
      isRequired: true,
      maxLength: 100,
    },
    phone: {
      isMobilePhone: true,
      isRequired: false,
    },
    firstName: {
      isRequired: true,
      maxLength: 100,
    },
    lastName: {
      isRequired: true,
      maxLength: 100,
    },
    middleName: {
      isRequired: false,
      maxLength: 100,
    },
  };
};

export const VideoServerValidationConfig = () => {
  return {
    name: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 50,
    },
    address: {
      isRequired: true,
      isIpOrUrl: true,
      maxLength: 250,
    },
    openedPort: {
      isRequired: true,
      isPort: true,
      minValue: 0,
      maxValue: 65535,
    },
    encryptedPort: {
      isRequired: true,
      isPort: true,
      minValue: 0,
      maxValue: 65535,
    },
    login: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 100,
    },
    password: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 100,
    },
  };
};

export const RtspServerValidationConfig = () => {
  return {
    name: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 50,
    },
    description: {
      isRequired: true,
      maxLength: 250,
    },
    apiUrl: {
      isRequired: true,
      isIpOrUrl: true,
      maxLength: 250,
    },
    rtspConnectPort: {
      isRequired: true,
      isPort: true,
      minValue: 0,
      maxValue: 65535,
    },
    login: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 100,
    },
    password: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 100,
    },
    rangeMask: {
      isRequired: true,
    },
    rangeIpAddress: {
      isRequired: true,
      isIpOrUrl: true,
      maxLength: 250,
    },
    rangeExcludeIpAddresses: {
      isRequired: false,
      joinStringIsIP: true,
      maxLength: 250,
    },
  };
};

export const SettingsValidationConfig = () => {
  return {
    ipCamLogin: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 100,
    },
    ipCamPassword: {
      isRequired: true,
      onlyLatAndNumbers: true,
      withoutSpaces: true,
      maxLength: 100,
    },
    ipCamStreamUrlLifetime: {
      onlyNumbers: true,
      withoutSpaces: true,
      minValue: 0,
    }
  };
};

export const DeviceValidationConfig = () => {
  return {
    name: {
      isRequired: true,
      maxLength: 200,
    },
    code: {
      isRequired: true,
      maxLength: 100,
    },
    description: {
      isRequired: false,
      maxLength: 200,
    },
    deviceCategoryId: {
      isRequired: true,
    },
    deviceSeriesId: {
      isRequired: true,
    },
  };
};

export const DeviceParameterValidationConfig = ({ customUniq }) => {
  return {
    name: {
      isRequired: true,
      maxLength: 100,
      customUniq: customUniq || [],
    },
    description: {
      maxLength: 100,
      isRequired: true,
    },
    type: {
      isRequired: true,
    },
    fieldType: {
      isRequired: true,
    },
  };
};
export const DeviceParameterDefaultDataValidationConfig = ({
  minValueNumber,
  maxLengthNumber,
  minLengthString,
  maxLengthString,
  FromMinValue,
  FromMaxValue,
  ToMinValue,
  ToMaxValue,
}) => {
  return {
    minValueNumber: {
      isRequired: false,
      maxValue: 100000000,
    },
    maxLengthNumber: {
      isRequired: false,
      maxValue: 100000000,
    },
    defaultValueNumber: {
      isRequired: false,
      minValue: minValueNumber || 0,
      maxValue: maxLengthNumber || 100000000,
    },
    minLengthString: {
      isRequired: false,
      maxValue: 100000000,
    },
    maxLengthString: {
      isRequired: false,
      maxValue: 100000000,
    },
    defaultValueString: {
      isRequired: false,
      minLength: minLengthString || 0,
      maxLength: maxLengthString || 100000000,
    },
    defaultValueBoolean: {
      isRequired: false,
    },
    FromMinValue: {
      isRequired: false,
      maxValue: 100000000,
    },
    FromMaxValue: {
      isRequired: false,
      maxValue: 100000000,
    },
    ToMinValue: {
      isRequired: false,
      maxValue: 100000000,
    },
    ToMaxValue: {
      isRequired: false,
      maxValue: 100000000,
    },
    fromErrorDefaultValue: {
      isRequired: false,
      minValue: FromMinValue || 1,
      maxValue: FromMaxValue || 100000000,
    },
    toErrorDefaultValue: {
      isRequired: false,
      minValue: ToMinValue || 1,
      maxValue: ToMaxValue || 100000000,
    },
    maxCount: {
      isRequired: false,
    },
  };
};

export const DeviceParameterCategoryValidationConfig = () => {
  return {
    groupName: {
      maxLength: 200,
      isRequired: true,
    },
  };
};
export const InvitedAdminValidationConfig = ({ checkPassword }) => {
  return {
    password: {
      isRequired: true,
      minLength: 8,
      maxLength: 100,
      requireNumberSymbol: true,
      onlyLatAndNumbers: true,
    },
    passwordAgain: {
      isRequired: true,
      minLength: 8,
      maxLength: 100,
      requireNumberSymbol: true,
      matchWith: checkPassword || '',
    },
    firstName: {
      isRequired: true,
      maxLength: 100,
    },
    lastName: {
      isRequired: true,
      maxLength: 100,
    },
    position: {
      isRequired: true,
      maxLength: 100,
    },
  };
};
export const ResetPasswordValidationConfig = ({ checkPassword }) => {
  return {
    password: {
      isRequired: true,
      minLength: 8,
      maxLength: 100,
      requireNumberSymbol: true,
      onlyLatAndNumbers: true,
    },
    passwordAgain: {
      isRequired: true,
      minLength: 8,
      maxLength: 100,
      requireNumberSymbol: true,
      matchWith: checkPassword || '',
    },
  };
};

export const ForgotPasswordValidationConfig = () => {
  return {
    email: {
      isRequired: true,
      isEmail: true,
      maxLength: 100,
    },
  };
};

export const CompanyRatesValidationConfig = () => {
  return {
    percentageCommission: {
      maxLength: 3,
      minValue: 0,
      maxValue: 100,
    },
    fixCommission: {
      minValue: 0,
      maxValue: 999999.98,
    },
    paymentCompanyId: {
      isRequired: true,
    },
  };
};
export const KeyWriterValidationConfig = () => {
  return {
    version: {
      isRequired: true,
    },
  };
};
export const DefaultTariffConfig = () => {
  return {
    name: {
      isRequired: true,
      maxLength: 80,
    },
    description: {
      isRequired: true,
      maxLength: 1000,
    },
    Lite: {
      isRequired: true,
      maxValue: 999999.99,
    },
    Medium: {
      isRequired: true,
      maxValue: 999999.99,
    },
    Comfort: {
      isRequired: true,
      maxValue: 999999.99,
    },
  };
};
export const InstuctionValidationConfig = () => {
  return {
    title: {
      isRequired: true,
      maxLength: 90,
    },
    description: {
      isRequired: true,
    },
    category: {
      isRequired: true,
    },
  };
};
export const FaqCategoryValidationConfig = () => {
  return {
    title: {
      isRequired: true,
      maxLength: 75,
    },
  };
};
export const InstructionCategoryValidationConfig = () => {
  return {
    title: {
      isRequired: true,
      maxLength: 75,
    },
  };
};
export const FaqValidationConfig = () => {
  return {
    title: {
      isRequired: true,
      maxLength: 250,
    },
    description: {
      isRequired: true,
    },
    category: {
      isRequired: true,
    },
  };
};

export const DeviceVersionAndRevisionAddForm = () => {
  return {
    revision: {
      isRequired: true,
    },
    version: {
      isRequired: true,
      minValue: 0,
      maxValue: 999999999,
    },
  };
};

export const SingleRoleValidationConfig = () => {
  return {
    RoleName: {
      isRequired: true,
      maxLength: 50,
    },
  };
};

export const CompanySmsValidationConfig = () => {
  return {
    SmsCount: {
      isRequired: true,
    },
  };
};

export const CompanySettingsValidationConfig = () => {
  return {
    maxCameraCount: {
      isRequired: true,
      minValue: 1
    },
    itvSystemId: {
      isRequired: true,
    },
    volumeLabel: {
      isRequired: true,
      maxLength: 50
    },
    volumeSize: {
      isRequired: true,
      minValue: 0
    },
    storageBucket: {
      isRequired: true,
      maxLength: 1000
    },
    storageRegion: {
      isRequired: true,
      maxLength: 100
    },
    storageAccessKeyId: {
      isRequired: true,
      maxLength: 1000
    },
    storageSecretAccessKey: {
      isRequired: true,
      maxLength: 1000
    },
    storagePath:  {
      isRequired: true,
      maxLength: 50
    },
    storageHost:  {
      isRequired: true,
      maxLength: 255
    },
    storageBucketEndpoint: {
      isRequired: true,
      maxLength: 1000
    }
  };
};
