import React, { useEffect, useMemo, useState } from 'react';
import { FullPageLayout } from '../Default/Layout/FullPageLayout';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useNavigate } from 'react-router-dom';
import { URLS } from '../../utils/urls.util';
import { DeviceParametersCategoriesList } from '../DeviceParametersCategoriesList/DeviceParametersCategoriesList';
import {
  editDeviceVersionRevision,
  getOldVersionRevision,
  getOneDeviceRevisionMap,
  getVersionRevisionFile,
  getVersionRevisionUsage,
  removeDeviceVersionRevision,
  uploadDeviceFile,
} from '../../api/DeviceApi';
import {
  DeviceParameterCategoryValidationConfig,
  DeviceValidationConfig,
} from '../../utils/formValidatorConfigs/config';
import { useApi } from '../../hooks/useApi';
import { UploadFileDeviceModal } from '../UploadFileModal/UploadFileDeviceModal';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { ActionApproveV2 } from '../Default/ActionApprove/ActionApproveV2';
import { setAlert } from '../../store/alert.store';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { FormValidator } from '../FormValidator/FormValidator';
import SortableList, { SortableItem } from 'react-easy-sort';
import arrayMove from 'array-move';
import _ from 'lodash';
import { FooterButtonBar } from '../Default/FooterButtonBar/FooterButtonBar';
import { saveAs } from 'file-saver';
import styles from './SingleRevision.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const SingleRevision = ({
  device,
  revision,
  version,
  versionRevision,
  isCreate,
  setVersionRevision,
  isExist,
  permission,
}) => {
  const [deviceState, setDeviceState] = useState({});
  const [deviceCategories, setDeviceCategories] = useState([]);
  const [editCategory, setEditCategory] = useState(false);
  const [validateErrors, setValidateErrors] = useState({});
  const [formDisabled, setFormDisabled] = useState(true);
  const [wasChange, setChange] = useState(false);
  const [listOfTitle, setListOfTitle] = useState([]);
  const [changedStateChecker, setChangedStateChecker] = useState(false);
  const [parameterCheckerList, setParameterCheckerList] = useState([]);
  const [modal, setModal] = useState({});
  const config = useMemo(() => DeviceValidationConfig(), []);
  const [removedCount, setRemovedCount] = useState(0);

  const [previousVersions, setPreviousVersions] = useState([]);

  const configCategory = useMemo(
    () => DeviceParameterCategoryValidationConfig(),
    [],
  );

  const [editableCategory, setEditableCategory] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [finalData, setFinalData] = useState({});
  const [listOfCategoryTitle, setListOfCategoryTitle] = useState([]);
  const [versionRevisionUsage, setVersionRevisionUsage] = useState([]);

  const [versionRevisionList, setVersionRevisionList] = useState([]);
  const getOneDeviceRevisionMapApi = useApi(getOneDeviceRevisionMap);
  const editDeviceVersionRevisionApi = useApi(editDeviceVersionRevision);
  const removeDeviceVersionRevisionApi = useApi(removeDeviceVersionRevision);
  const getOldVersionRevisionApi = useApi(getOldVersionRevision);
  const getVersionRevisionFileApi = useApi(getVersionRevisionFile);
  const getVersionRevisionUsageApi = useApi(getVersionRevisionUsage);
  const [canSaveByCategoryList, setCanSaveByCategoryList] = useState(true);
  const [changeParams, setChangeParams] = useState(false);
  const navigate = useNavigate();

  const handleChangePreviousVersions = (event) => {
    const {
      target: { value },
    } = event;

    setFinalData({
      ...finalData,
      previousVersions: typeof value === 'string' ? [] : value,
    });
  };

  useEffect(() => {
    if (categoryList?.length) {
      if (
        categoryList?.some(
          (category) => !category?.accessPointTypeParams?.length,
        )
      ) {
        setCanSaveByCategoryList(false);
      } else {
        setCanSaveByCategoryList(true);
      }
    }
  }, [categoryList]);
  useEffect(() => {
    if (!isCreate)
      setFinalData({
        ...versionRevision,
        previousVersions: versionRevision?.previousVersions?.map(
          (pv) => pv.name,
        ),
      });
  }, [versionRevision]);
  useEffect(() => {
    if (device.id) {
      getOneDeviceRevisionMapApi.sendRequest(device.id).then((result) => {
        const versionRevisionList = [];
        for (const row of result?.rows) {
          for (const cell of row?.cells) {
            if (cell?.versionRevisionId) {
              const versionName = result?.rows.find(
                (r) => r.versionId == cell.versionId,
              )?.versionName;
              const revisionName = result?.headers.find(
                (h) => h.revisionId == cell.revisionId,
              )?.revisionName;
              versionRevisionList.push({
                id: cell?.versionRevisionId,
                name: `Версия - ${versionName}, ревизия - ${revisionName}`,
              });
            }
          }
        }

        setVersionRevisionList(versionRevisionList);
      });
    }
  }, [device]);

  useEffect(() => {
    if (device?.id && revision?.revisionId && version?.versionId) {
      getOldVersionRevisionApi
        .sendRequest({
          deviceId: device.id,
          revisionId: revision.revisionId,
          versionId: version.versionId,
        })
        .then((result) => setPreviousVersions(result));
    }
  }, [device, version, revision]);

  const downloadRemoteFile = async () => {
    const resultByte = await getVersionRevisionFileApi.sendRequest({
      deviceId: device.id,
      versionRevisionId: versionRevision?.id,
    });

    saveAs(resultByte, `${version?.versionName}.zip`);
  };
  useEffect(() => {
    let inputs = document.getElementsByTagName('input');
    let elemets = Array.prototype.slice.call(inputs);
    setChangedStateChecker(elemets?.some((el) => el?.required && !el.value));
  }, [
    deviceState,
    deviceCategories,
    editCategory,
    validateErrors,
    formDisabled,
    wasChange,
    listOfTitle,
    parameterCheckerList,
  ]);
  useEffect(() => {
    const configKeys = Object.keys(config);

    if (wasChange && Object.keys(validateErrors).length === 0) {
      let canSave = true;
      configKeys.forEach((key) => {
        if (canSave && config[key]?.isRequired && !(key in deviceState)) {
          canSave = false;
        }
      });
      setFormDisabled(!canSave);
    } else if (!formDisabled) {
      setFormDisabled(true);
    }
  }, [validateErrors]);

  useEffect(() => {
    let result = [];
    if (versionRevision?.accessPointTypeParams?.length) {
      const newResult = versionRevision?.accessPointTypeParams?.map((p) => {
        return {
          ...p,
          accessPointTypeParams: versionRevision?.accessPointTypeParams?.filter(
            (pp) => pp.groupName == p.groupName,
          ),
        };
      });
      for (const newParam of newResult) {
        const candidate = result?.find(
          (r) => r.groupName == newParam?.groupName,
        );
        const candidateIndex = result?.findIndex(
          (r) => r.groupName == newParam?.groupName,
        );

        if (candidate) {
          const newAccessPointTypeParams = [];
          for (const p of newParam?.accessPointTypeParams) {
            if (
              result[candidateIndex]?.accessPointTypeParams?.some(
                (pp) => pp.id != p.id,
              )
            ) {
              newAccessPointTypeParams.push(p);
            }
          }
          result[candidateIndex] = {
            ...result[candidateIndex],
            accessPointTypeParams: [
              ...newAccessPointTypeParams.map((p) => {
                return {
                  ...p,
                  restricts: {
                    ...p.restricts,
                    defaultValue: p.defaultValue,
                  },
                };
              }),
            ],
          };
        } else {
          result.push({
            ...newParam,
            accessPointTypeParams: newParam?.accessPointTypeParams.map((p) => {
              return {
                ...p,
                restricts: {
                  ...p.restricts,
                  defaultValue: p.defaultValue,
                },
              };
            }),
          });
        }
      }
    }
    const currentAccessPointIds = [].concat(
      ...categoryList
        .map((c) => c.accessPointTypeParams)
        ?.map((item) => item.id),
      // ?.filter((item) => item),
    );
    const resultAccessPointIds = []
      .concat(...result.map((c) => c.accessPointTypeParams))
      ?.map((item) => item.id);
    // ?.filter((item) => item);

    if (
      _.isEqual(currentAccessPointIds, resultAccessPointIds) ||
      changeParams
    ) {
      setCategoryList(
        result.sort((a, b) => (a.groupNameOrder > b.groupNameOrder ? 1 : -1)),
      );
      setChangeParams(false);
    } else {
      setCategoryList(
        categoryList?.length || (categoryList?.length == 0 && removedCount)
          ? categoryList?.sort((a, b) =>
              a.groupNameOrder > b.groupNameOrder ? 1 : -1,
            )
          : result?.sort((a, b) =>
              a.groupNameOrder > b.groupNameOrder ? 1 : -1,
            ),
      );
    }

    setListOfTitle(
      [].concat(
        ...(result?.map((r) =>
          r?.accessPointTypeParams?.map((a) => ({ id: a?.id, name: a?.name })),
        ) || []),
      ),
    );
  }, [finalData, versionRevision]);

  const onChange = (value, fieldName) => {
    if (!wasChange) {
      setChange(true);
    }
    setFinalData({ ...finalData, [fieldName]: value });
  };

  const parameterCheckerHandler = (data) => {
    if (parameterCheckerList.some((item) => item.id == data.id)) {
      setParameterCheckerList(
        parameterCheckerList.map((item) => {
          if (item.id == data.id) {
            item.canSave = data.canSave;
          }
          return item;
        }),
      );
    } else {
      setParameterCheckerList([...parameterCheckerList, data]);
    }
  };

  const dispatch = useDispatch();
  const saveVersionRevisionData = async () => {
    const accessPointTypeParams = [];
    for (const cat of categoryList) {
      for (const param of cat.accessPointTypeParams) {
        if (
          (param.restricts?.type == 'Number' ||
            param.restricts?.type == 'NumberRange' ||
            param.restricts?.type == 'ArrayNumberRange') &&
          param.restricts?.defaultValue === ''
        ) {
          param.defaultValue = null;
          param.restricts.defaultValue = null;
        }
        accessPointTypeParams.push({
          ...param,
          groupName: cat.groupName,
          groupNameOrder: cat.groupNameOrder,
        });
      }
    }
    const pvResult = [];
    for (const pv of previousVersions) {
      if (finalData.previousVersions?.indexOf(pv.name) > -1) {
        pvResult.push(pv);
      }
    }
    const resultFinalData = {
      ...finalData,
      isActive: finalData?.isActive || false,
      isIpCamAvailable: finalData?.isIpCamAvailable || false,
      isFileDownloadAvailable: finalData?.isFileDownloadAvailable || false,
      accessPointTypeParams: accessPointTypeParams,
      isFileDownloadAvailable:
        (device?.deviceSeriesId === 'b923fe31-a23b-4731-8aaf-2e9875eb7000' &&
          device.deviceCategoryId === 'b923fe31-a23b-4731-8aaf-2e9875eb6f01') ||
        device?.deviceSeriesId === 'b923fe31-a23b-4731-8aaf-2e9875eb5000'
          ? false
          : finalData.isFileDownloadAvailable,
      previousVersions: pvResult,
    };

    const result = await editDeviceVersionRevisionApi.sendRequest(device.id, {
      ...resultFinalData,
      versionId: version.versionId,
      revisionId: revision.revisionId,
      revision: revision.revisionName,
      version: version.versionName,
    });

    dispatch(
      setAlert({
        status: 200,
        text: 'Изменения по версиям и ревизиям успешно сохранены',
      }),
    );
    navigate(`${URLS.DEVICES}/${device.id}/revisions`);
  };
  const removeVersionRevision = async () => {
    await removeDeviceVersionRevisionApi.sendRequest(
      device.id,
      versionRevision.id,
    );
    navigate(URLS.DEVICES + '/' + device.id + '/revisions');
    dispatch(
      setAlert({ status: 200, text: 'Комбинация версии и ревизии удалена' }),
    );
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setCategoryList((array) => {
      let newArray = arrayMove(array, oldIndex, newIndex);
      newArray = newArray.map((item, index) => ({
        ...item,
        groupNameOrder: index,
      }));
      return newArray;
    });
  };

  const onUsageBlockToggle = (e, expanded) => {
    if (!expanded || versionRevisionUsage.length) return;
    getVersionRevisionUsageApi.sendRequest(versionRevision.id).then((data) => {
      setVersionRevisionUsage(data);
    });
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={URLS.DASHBOARD}>
      Главная
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={URLS.DEVICES}>
      Список типов оборудования
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={URLS.DEVICES + '/' + device.id + '/revisions'}
    >
      {device.name}
    </Link>,

    <Typography key="3" color="text.primary">
      Настройка ревизии {revision?.revisionName} и версии {version?.versionName}
    </Typography>,
  ];
  return (
    <FullPageLayout
      obj={revision}
      seoTitle={`Ревизии - настройка ревизии ${revision?.revisionName} и версии ${version?.versionName}`}
      pageTitle={revision?.versionName}
      isCreated={true}
      breadcrumbs={breadcrumbs}
    >
      {isExist && (
        <Grid container spacing={4} alignItems="center" sx={{mb: 4}}>
          <Grid item xs={8}>
            <Alert color="warning" severity="warning">
              Ревизия/версия используется в АРМ. Для редактирования доступен
              только статус активности, тумблер отображения файла обновления в
              АРМ и список версий
            </Alert>
            <Accordion onChange={onUsageBlockToggle}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} className="revision-usage-block">
              Использующие организации
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} className={styles.usageGrid}>
                {versionRevisionUsage.map((item) => (<>
                    <Grid item><a href={`/companies/${item.companyId}/data?page=1`} target="_blank" rel="noreferrer">{item.companyName}</a></Grid>
                    <Grid item>{item.objects.map(o => o.name).join('\n')}</Grid>
                  </>
                )
                )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label-3">Статус</InputLabel>
            <Select
              labelId="demo-simple-select-label-3"
              id="demo-simple-select-3"
              name="isActive"
              defaultValue="0"
              value={+(finalData?.isActive || revision?.isActive || false)}
              label="Статус"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => onChange(!!+target.value, 'isActive')}
              disabled={!permission?.edit}
            >
              <MenuItem value={1}>Активно</MenuItem>
              <MenuItem value={0}>Не активно</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={3}>
          <FormControlLabel
            control={
              <Switch
                disabled={isExist || !permission?.edit}
                checked={finalData?.isIpCamAvailable || false}
                onChange={({ target }) =>
                  onChange(target.checked, 'isIpCamAvailable')
                }
              />
            }
            label="Наличие камеры"
          />
        </Grid>
      </Grid>
      {device?.deviceSeriesId === 'b923fe31-a23b-4731-8aaf-2e9875eb7000' &&
        device.deviceCategoryId === 'b923fe31-a23b-4731-8aaf-2e9875eb6f00' && (
          <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                {finalData?.id && (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!permission?.edit}
                          checked={finalData?.isFileDownloadAvailable}
                          onChange={({ target }) =>
                            onChange(target.checked, 'isFileDownloadAvailable')
                          }
                        />
                      }
                      label="Доступно для скачивания в АРМ"
                    />
                    <Stack direction="row" spacing={0} alignItems="center">
                      {getVersionRevisionFileApi.loading ? (
                        <CircularProgress size={10} sx={{ mr: 1 }} />
                      ) : (
                        <GetAppOutlinedIcon color="primary" />
                      )}
                      <a
                        href="#"
                        style={{
                          color: 'rgb(25, 118, 210)',
                          textDecoration: getVersionRevisionFileApi.loading
                            ? 'none'
                            : 'underline',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (!getVersionRevisionFileApi.loading) {
                            downloadRemoteFile();
                          }
                        }}
                      >
                        <Typography as="p" variant="p" color="primary">
                          Файл для обновления ПО ревизия{' '}
                          {revision?.revisionName} версия {version?.versionName}
                        </Typography>
                      </a>
                    </Stack>
                  </Stack>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <Typography sx={{ color: '#949494' }}>
                  Выберите версии, с которых доступно обновление до текущей.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ width: 'calc(100% - 10px)' }}>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Версии
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={finalData?.previousVersions || []}
                    onChange={handleChangePreviousVersions}
                    defaultChecked={{ id: 'no-checked', name: 'Не выбрано' }}
                    input={<OutlinedInput label="Версии" />}
                    defaultValue={'Не выбрано'}
                    renderValue={(selected) => {
                      return selected?.join(', ');
                    }}
                    MenuProps={MenuProps}
                  >
                    {previousVersions.map((pv, i) => (
                      <MenuItem key={i} value={pv.name}>
                        <Checkbox
                          checked={
                            finalData?.previousVersions?.indexOf(pv.name) > -1
                          }
                        />
                        <ListItemText
                          primary={pv.name}
                          onClick={() =>
                            handleChangePreviousVersions({
                              target: { value: pv.name },
                            })
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
      <Grid container spacing={2} sx={{ mb: 4, pb: 0, mt: 5 }}>
        <Grid item xs={12} sx={{ mb: 0, pb: 0 }}>
          <Typography as="h2" variant="h5" sx={{ mb: 0, pb: 0 }}>
            Параметры
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="dcreate-from-other-version-revision-select">
              Предзаполнить настройки как
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="create-from-other-version-revision-select"
              label="Предзаполнить настройки как"
              defaultValue={true}
              disabled={isExist || !permission?.edit}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={({ target }) => {
                if (target.value !== true) {
                  setModal({
                    title:
                      'Список групп и параметров предзаполнится выбранными значениями',
                    target: finalData,
                    open: true,
                    handleClose: () => setModal({ ...modal, open: false }),
                    actionButtonText: 'Продолжить',
                    handleSuccess: () => {
                      setChangeParams(true);
                      setVersionRevision({
                        ...finalData,
                        deviceId: device.id,
                        fromVersionRevisionId: target.value,
                      });
                      setModal({ ...modal, open: false });
                    },
                    showDescription: false,
                  });
                }
              }}
            >
              <MenuItem value={true}>Новая версия и ревизия</MenuItem>
              {versionRevisionList?.map((vr) => (
                <MenuItem value={vr?.id} key={vr?.id}>
                  {vr?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}>
          {!editCategory && permission?.edit && (
            <Button
              variant="text"
              color="primary"
              onClick={() => setEditCategory(true)}
              startIcon={<AddOutlinedIcon />}
              disabled={isExist || !permission?.edit}
            >
              Добавить группу
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 4, pb: 10 }}>
        <Grid item xs={8}>
          <SortableList
            onSortEnd={onSortEnd}
            className="list"
            draggedItemClassName="dragged"
          >
            {categoryList?.map((category, index) => (
              <SortableItem key={index}>
                <div>
                  <DeviceParametersCategoriesList
                    permission={permission}
                    index={index}
                    isExist={isExist}
                    listOfTitle={listOfTitle}
                    setListOfTitle={(data) => setListOfTitle(data)}
                    parameterCheckerHandler={(data) =>
                      parameterCheckerHandler(data)
                    }
                    category={category}
                    removeHandler={(data) => {
                      setCategoryList(
                        categoryList
                          .filter((c, i) => i !== index)
                          ?.sort((a, b) =>
                            a.groupNameOrder > b.groupNameOrder ? 1 : -1,
                          ),
                      );
                      setRemovedCount(true);
                    }}
                    updateCategoryHandler={(data) => {
                      setEditableCategory(data);
                      setEditCategory(true);
                    }}
                    updateCategoryParamsHandler={(data) => {
                      const candidate = categoryList.find(
                        (c) => c.groupNameOrder == data.groupNameOrder,
                      );
                      if (candidate) {
                        setCategoryList(
                          [
                            ...categoryList?.filter(
                              (c) => c.groupNameOrder !== data.groupNameOrder,
                            ),
                            { ...candidate, ...data },
                          ].sort((a, b) =>
                            a.groupNameOrder > b.groupNameOrder ? 1 : -1,
                          ),
                        );
                      }
                    }}
                    finalData={finalData}
                  />
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </Grid>
      </Grid>

      <FooterButtonBar
        showDeleteButton={versionRevision?.id && permission?.edit}
        deleteButtonHandler={() => {
          if (isExist) {
            setModal({
              open: true,
              title:
                'Вы не можете удалить данную комбинацию версии и ревизии, когда она используется в АРМ',
              actionButtonText: 'Понятно',
              showCloseButton: false,
              handleSuccess: () => setModal({ ...modal, open: false }),
              handleClose: () => setModal({ ...modal, open: false }),
            });
          } else {
            setModal({
              title:
                'Вы действительно хотите удалить данную комбинацию версии-ревизии?',
              target: finalData,
              open: true,
              handleClose: () => setModal({ ...modal, open: false }),
              actionButtonText: 'Удалить',
              handleSuccess: () => {
                removeVersionRevision();
                setModal({ ...modal, open: false });
              },
              showDescription: false,
            });
          }
        }}
        disabledDeleteButton={!permission?.edit}
        cancelButtonHandler={() =>
          navigate(URLS.DEVICES + '/' + device.id + '/revisions')
        }
        showCancelButton={true}
        showUpdateButton={false}
        disabledUpdateButton={true}
        showCreateButton={permission?.edit}
        createButtonHandler={() => {
          if (!versionRevision?.id) {
            setModal({
              title:
                'Вы действительно хотите создать тип оборудования? При использовании его в АРМ, его нельзя будет удалить',
              target: finalData,
              open: true,
              handleClose: () => setModal({ ...modal, open: false }),
              actionButtonText: 'Сохранить',
              handleSuccess: () => {
                saveVersionRevisionData();
                setModal({ ...modal, open: false });
              },
              showDescription: false,
            });
          } else {
            saveVersionRevisionData();
          }
        }}
        createButtonText="Сохранить"
        disabledCreateButton={
          permission?.edit
            ? editDeviceVersionRevisionApi.loading ||
              !canSaveByCategoryList ||
              Object.keys(validateErrors)?.length
            : true
        }
        loading={
          getOneDeviceRevisionMapApi.loading ||
          editDeviceVersionRevisionApi.loading ||
          removeDeviceVersionRevisionApi.loading
        }
      />

      <Modal
        open={editCategory}
        onClose={() => {
          setEditCategory(false);
          setEditableCategory(null);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box className="ModalStyle">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 5 }}
          >
            Добавление группы параметров
          </Typography>
          <FormValidator
            name={`rtsp-server-form-name-${uuidv4()}`}
            validationConfig={configCategory}
            changed={editableCategory}
            setErrorsHandler={(data) => {
              if (wasChange) {
                setValidateErrors(data);
              }
            }}
          >
            <TextField
              fullWidth
              type="text"
              value={editableCategory?.groupName}
              name="groupName"
              label="Название группы параметров"
              required={configCategory?.groupName?.isRequired}
              error={
                validateErrors?.groupName?.text ||
                listOfCategoryTitle.includes(editableCategory?.groupName)
                  ? true
                  : false
              }
              helperText={
                validateErrors?.groupName?.text
                  ? validateErrors?.groupName?.text
                  : listOfCategoryTitle.includes(editableCategory?.groupName)
                  ? 'Название категории должно быть уникальным в рамках одной комбинации ревизии и версии'
                  : ''
              }
              onChange={({ target }) => {
                setEditableCategory({
                  ...editableCategory,
                  groupName: target.value,
                });
                if (!wasChange) {
                  setChange(true);
                }
              }}
            />
          </FormValidator>
          <Stack
            direction="row"
            spacing={2}
            sx={{ mt: 10 }}
            justifyContent="end"
          >
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => setEditCategory(false)}
            >
              Отмена
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                Object.keys(validateErrors)?.length ||
                listOfCategoryTitle.includes(editableCategory?.name)
              }
              onClick={() => {
                const candidate = categoryList.find(
                  (c) =>
                    c.id && editableCategory.id && c.id == editableCategory.id,
                );
                let result;
                if (candidate) {
                  result = [
                    ...categoryList?.filter(
                      (c) => c.id !== editableCategory.id,
                    ),
                    {
                      ...candidate,
                      groupName: editableCategory.groupName,
                      id: editableCategory?.id || uuidv4(),
                    },
                  ];
                } else {
                  result = [
                    ...categoryList,
                    {
                      id: editableCategory?.id || uuidv4(),
                      groupName: editableCategory.groupName,
                      groupNameOrder: categoryList?.length,
                      accessPointTypeParams: [],
                    },
                  ];
                }

                result = result?.sort((a, b) =>
                  a.groupNameOrder > b.groupNameOrder ? 1 : -1,
                );
                setCategoryList(result);
                setEditableCategory({});
                setEditCategory(false);
              }}
            >
              Сохранить
            </Button>
          </Stack>
        </Box>
      </Modal>
      <ActionApproveV2
        target={modal.target}
        open={modal.open}
        title={modal.title}
        handleClose={modal.handleClose}
        actionButtonText={modal.actionButtonText}
        handleSuccess={modal.handleSuccess}
        showDescription={modal.showDescription}
        showCloseButton={modal?.showCloseButton}
      />
    </FullPageLayout>
  );
};
